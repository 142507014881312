
import {
  apiThirdOrderList,
  apiOrderDistribution,
  apiSetDelivery,
  apiSetDeliverySn,
} from "@/api/order";
import { apiShopLists } from "@/api/shop";
import { Component, Prop, Vue } from "vue-property-decorator";
import lsPagination from "@/components/ls-pagination.vue";
import ExportData from "@/components/export-data/index.vue";
import LsDialog from "@/components/ls-dialog.vue";
import { RequestPaging } from "@/utils/util";
import PopoverSelect from "@/components/popover-select.vue";
import { apiFactoryLists } from "@/api/shop";
import DatePicker from "@/components/date-picker.vue";
import { apiFaceSheetTemplateLists } from "@/api/setting";

@Component({
  components: {
    lsPagination,
    ExportData,
    LsDialog,
    PopoverSelect,
    DatePicker,
  },
})
export default class ShopList extends Vue {
  /** S Data **/

  apiThirdOrderList = apiThirdOrderList;

  pager: RequestPaging = new RequestPaging();

  form = {
    production_status: "",
    audit_status: "",
    express_status: "",
    shop_info: "",
    start_time: "",
    end_time: "",
    time_type: "",
    source: "",
    sid: "",
  };
  pushForm = {
    id: 0,
    fid: null,
    temp_id: null,
  };
  shopList: any[] = [];
  shopCopyList: any[] = [];
  shopMore = false;
  shop = "";

  production_status = {
    0: "待接单",
    1: "生产中",
    2: "已完成",
  };

  productionArr = [
    { value: "", label: "全部" },
    { value: 0, label: "待接单" },
    { value: 1, label: "生产中" },
    { value: 2, label: "已完成" },
  ];

  auditArr = [
    { value: "", label: "全部" },
    { value: 0, label: "未审核" },
    { value: 1, label: "已审核" },
  ];

  expressArr = [
    { value: "", label: "全部" },
    { value: 0, label: "未发货" },
    { value: 1, label: "已发货" },
  ];

  sourceArr = [
    { value: "", label: "全部" },
    { value: 1, label: "内部订单" },
    { value: 2, label: "第三方订单" },
  ];

  deliveryList = [
    { value: "", label: "全部" },
    { value: 1, label: "极兔" },
    { value: 2, label: "递四方" },
    // { value: 1, label: '极兔' },
  ];

  factoryList = [];
  delivery_sn = "";

  templateList = [];
  /** E Data **/

  /** S Methods **/

  // 获取商城列表

  getOrderList(page?: number) {
    page && (this.pager.page = page);
    this.pager
      .request({
        callback: apiThirdOrderList,
        params: {
          ...this.form,
        },
      })
      .then((res) => {});
  }
  // 去订单详情
  toOrder(id: any) {
    this.$router.push({
      path: "/order/order_edit",
      query: { id },
    });
  }
  handleFactory(event: any, order_id: any) {
    apiOrderDistribution({ order_id: order_id, factory_id: event }).then(
      (res) => {
        this.$message.success("推送成功");
        this.query();
      }
    );
  }

  handleFactory1(event: any, order_id: any) {
    this.pushForm.id = order_id;
    apiSetDelivery(this.pushForm).then((res) => {
      this.$message.success("推送成功");
      this.query();
    });
  }

  query() {
    this.pager.page = 1;
    this.getOrderList();
  }
  setDeliverySn(id: any) {
    apiSetDeliverySn({ id: id, delivery_sn: this.delivery_sn }).then((res) => {
      this.$message.success("设置成功");
    });
  }
  // 重置搜索
  reset(): void {
    Object.keys(this.form).map((key) => {
      this.$set(this.form, key, "");
    });
    this.query();
  }
  fleterShop(e: any) {
    const shopList = JSON.parse(JSON.stringify(this.shopList));
    this.shopCopyList = [{ id: "", name: "全部" }];
    for (let i of shopList) {
      if (i.name.indexOf(e) > -1) {
        this.shopCopyList.push(i);
      }
    }
  }
  /** E Methods **/

  /** S ods **/
  created() {
    this.getOrderList();
    apiFactoryLists({}).then((res: any) => {
      this.factoryList = res.lists;
    });
    apiFaceSheetTemplateLists({}).then((res: any) => {
      this.templateList = res.lists;
    });
    apiShopLists({ page_size: 999 }).then((res) => {
      this.shopList = res.lists;
      this.shopList.unshift({ id: "", name: "全部" });
      this.shopCopyList = res.lists;
    });
  }
}
